
import styled from 'styled-components';

const CountdownContainer = styled.div`
    font-size: 50px;
    color: white;
    padding: 20px;
`;

const HolidayCountdown = () => {
    return (
        <CountdownContainer>
            Big G Man is Watching 
        </CountdownContainer>
    );
};

export default HolidayCountdown;