import styled from 'styled-components';
import './App.css';
import HolidayCountdown from './Countdown/HolidayCountdown';
import Glenn from './assets/images/Glenn.jpeg';

const AppContainer =  styled.div`
  text-align: center;
  background-image: url(${Glenn});
  background-position: center;
  background-size: cover;
  background-color: #9ec9ad;
`;

const AppHeader = styled.header `
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
  color: white;
`;

function App() {
  return (
    <AppContainer>
      <AppHeader className="App-header">
        <HolidayCountdown />
      </AppHeader>
    </AppContainer>
  );
}

export default App;
